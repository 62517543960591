/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_create_hcp_account_by_vm_vm_request_post } from '../models/Body_create_hcp_account_by_vm_vm_request_post';
import type { ResponseRequestId } from '../models/ResponseRequestId';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RequestsSendByPfInternalPeopleService {

    /**
     * Add a new hcp account request by PF internal people
     * @param formData
     * @returns ResponseRequestId Successful Response
     * @throws ApiError
     */
    public static createHcpAccountByVmVmRequestPost(
        formData: Body_create_hcp_account_by_vm_vm_request_post,
    ): CancelablePromise<ResponseRequestId> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/vm/request',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}