import { Button, MantineProvider, Space } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import {
  OpenApi,
  useAuthStore,
  useIntlHelper,
  User,
  UserProfileForm,
  formatData,
  userToHcpAccountUpdate,
  userToHcpCreateByHcp,
  SourceSiteType,
} from "@pfid/common";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Profile({ edit = false }: { edit?: boolean }) {
  const { authStore } = useAuthStore();
  const navigate = useNavigate();
  const t = useIntlHelper();
  const [userData, setUserData] = useState<User>();

  function getUserData() {
    const accountId = authStore.userData.account_id;
    OpenApi.HcpAccountsService.findAccountByIdHcpAccountAccountIdGet(accountId)
      .then((data) => {
        setUserData(formatData(data));
      })
      .catch((error) => {
        showNotification({
          message: error.body ? error.body.detail : error.message.detail,
          color: "red",
        });
      });
  }

  useEffect(() => {
    console.log("Profile.useEffect()");
    if (
      authStore.userData.account_id === undefined ||
      authStore.userData.account_id === ""
    ) {
      console.log("Profile.useEffect() -> no account_id");
    } else {
      const accountId = authStore.userData.account_id;
      console.log(`Profile.useEffect() -> account_id=${accountId}`);
      getUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore.userData.account_id]);

  const editProfile = (values: User) => {
    if (!userData) return;
    OpenApi.HcpAccountsService.modifyAccountHcpAccountAccountIdPut(
      authStore.userData.account_id,
      {
        hcp_account_to_update: userToHcpAccountUpdate(
          userToHcpCreateByHcp(values),
          userToHcpCreateByHcp(userData)
        ),
        professional_certificates: values.professional_certificates,
        ribs: values.ribs,
        payment_certificates: values.payment_certificates,
      }
    )
      .then(() => {
        showNotification({
          message: t("toast.editSuccess"),
          color: "green",
        });
        getUserData();
        navigate("/profile");
      })
      .catch((error) => {
        showNotification({
          message: error.body ? error.body.detail : error.message.detail,
          color: "red",
        });
      });
  };

  return (
    <MantineProvider>
      {userData && (
        <>
          <UserProfileForm
            readOnly={!edit}
            data={userData}
            saveForm={edit ? editProfile : undefined}
            simple={!userData.delivery_address?.address}
            sourceSite={
              userData.source_site
                ? userData.source_site
                : SourceSiteType.PORTAL
            }
          />
          {!edit ? (
            <Button
              onClick={() => {
                navigate("/profile/edit");
              }}
              disabled={userData.status === "PENDING"}
            >
              {t("button.profile.edit")}
            </Button>
          ) : null}
          <Space h="xl" />
          <Space h="xl" />
        </>
      )}
    </MantineProvider>
  );
}
