import { MantineThemeOverride } from "@mantine/core";
import { defaultTheme } from "./index";

const pf4MedTheme: MantineThemeOverride = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
  },
};

export default pf4MedTheme;
