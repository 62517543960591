import React, { createContext, useState, useContext, useEffect } from "react";
import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { getNavigatorLanguage } from "@pfid/common";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { IntlProvider } from "react-intl";

import messages_fr from "./translations/fr";
import messages_en from "./translations/en";
import messages_es from "./translations/es";
import messages_it from "./translations/it";
import { defaultTheme } from "./themes";
import "./App.css";

import { CreationCompte, Profil, Confirm } from "./routes";
import ProfileApp from "./routes/ProfileApp";

import { LocaleContextType } from "@pfid/common/src/client";
import ReactFlagsSelect from "react-flags-select";

const locale_init = LocaleContextType[getNavigatorLanguage() as keyof typeof LocaleContextType]

function getMessages(locale: LocaleContextType) {
  switch (locale) {
    case LocaleContextType.FR:
      return messages_fr;
    case LocaleContextType.IT:
      return messages_it;
    case LocaleContextType.ES:
      return messages_es;
    default:
      return messages_en;
  }
}

function App() {

  const [currentLocale, setCurrentLocale] = useState<LocaleContextType>(locale_init);
  const [selectedCode, setSelectedCode] = useState<string>(currentLocale);

  useEffect(() => {
    if (currentLocale === LocaleContextType.EN) {
      setSelectedCode("GB")
    } else {
      setSelectedCode(currentLocale)
    }
  }, []);


  useEffect(() => {
    var localType = LocaleContextType[selectedCode as keyof typeof LocaleContextType]
    if (!localType || localType === LocaleContextType.GB)
      localType = LocaleContextType.EN
    setCurrentLocale(localType)
  }, [selectedCode]);

  return (

    <IntlProvider
      locale={currentLocale}
      messages={getMessages(currentLocale)}
      defaultLocale="en"
    >
      <div style={{ position: "absolute", paddingTop: 10, right: 10, zIndex: 1000 }}>
        <ReactFlagsSelect
          countries={["GB", "FR", "ES", "IT"]}
          selected={selectedCode}
          onSelect={(code: string) => setSelectedCode(code)}
          fullWidth={false}
        />
      </div>

      <MantineProvider withNormalizeCSS withGlobalStyles theme={defaultTheme}>
        <NotificationsProvider>
          <BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
            <Routes>
              <Route path="request" element={<CreationCompte />} />
              <Route path="profile" element={<ProfileApp />}>
                <Route index element={<Profil />} />
                <Route path="edit" element={<Profil edit />} />
              </Route>
              <Route
                path="confirm/:activationKey/:b64email"
                element={<Confirm />}
              />
            </Routes>
          </BrowserRouter>
        </NotificationsProvider>
      </MantineProvider>
    </IntlProvider>
  );
}

export default App;
