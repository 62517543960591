import { Box, Group, Space, Text, Tooltip } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";

import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { useState } from "react";
import { Trash } from "tabler-icons-react";

import { useIntlHelper } from "../../utils/misc";

type FilesState = {
  professional_certificates?: Array<File>;
  ribs?: Array<File>;
  payment_certificates?: Array<File>;
};

type KeyInputFile =
  | "professional_certificates"
  | "ribs"
  | "payment_certificates";

type DropzoneInputProps = {
  getInputProps: any;
  inputKey: KeyInputFile;
  disabled?: boolean;
  tooltip?: string;
  required?: boolean;
};

const DropzoneInput = ({
  getInputProps,
  inputKey,
  disabled,
  tooltip,
  required = true,
}: DropzoneInputProps) => {
  const t = useIntlHelper();
  const [files, setFiles] = useState<FilesState>();
  const error = getInputProps(inputKey).error;

  const setAcceptedFiles = (key: KeyInputFile, arrayToSet: Array<File>) => {
    setFiles((prevValue) => ({
      ...prevValue,
      [key]: arrayToSet,
    }));
    getInputProps(key).onChange(arrayToSet);
  };

  const removeAFile = (key: KeyInputFile, path: string) => {
    if (!files) return;
    // @ts-expect-error path exist
    const newArray = files?.[key]?.filter((file) => file.path !== path);
    setAcceptedFiles(key, newArray ?? []);
  };

  const displayFiles = (key: KeyInputFile) => {
    return files?.[key]?.map((file) => (
      <Group key={file.name}>
        {/* @ts-expect-error path exist */}
        <li>{file.path}</li>
        <Trash
          style={{ cursor: "pointer" }}
          // @ts-expect-error path exist
          onClick={() => removeAFile(key, file.path)}
        />
      </Group>
    ));
  };

  return (
    <Box key={inputKey}>
      <Space h="md" />
      <Text
        sx={{
          marginBottom: 4,
          fontSize: 14,
          fontWeight: 500,
          display: "flex",
          alignItems: "center",
        }}
      >
        {t(`form.${inputKey}`)}
        { required ?
        <Box component="span" sx={{ fontSize: 14, color: "#f03e3e" }}>
          {" "}
          *
        </Box>
        : <></>
        }

        {tooltip ? (
          <Tooltip
            label={t(tooltip)}
            position="top-end"
            withArrow
            multiline
            width={220}
          >
            <div>
              <IconAlertCircle
                size={18}
                style={{ display: "block", opacity: 0.5 }}
              />
            </div>
          </Tooltip>
        ) : (
          ""
        )}
      </Text>
      <Dropzone
        sx={{ height: 62 }}
        multiple
        onDrop={(acceptedFiles) => {
          getInputProps(inputKey).onChange(acceptedFiles);
          setFiles((prevValue) => ({
            ...prevValue,
            [inputKey]: acceptedFiles,
          }));
        }}
        accept={[MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.pdf]}
        disabled={disabled}
      >
        <Group
          position="center"
          spacing="xl"
          style={{ minHeight: 10, pointerEvents: "none" }}
        >
          <div>
            <Text size="sm" color="dimmed" inline mt={7}>
              {t("dropzone.message")}
            </Text>
          </div>
        </Group>
      </Dropzone>
      {error && (
        <Text
          sx={(theme) => ({
            fontSize: 12,
            color: theme.colors.red[6],
          })}
        >
          {error}
        </Text>
      )}
      <ul>{displayFiles(inputKey)}</ul>
    </Box>
  );
};

export default DropzoneInput;
