import { User } from "./UserProfileForm";

import {
  PaymentMethodType,
  ProfessionType,
  SourceSiteType,
} from "../../client";
import { SpecialityType } from "../../schemas/SpecialitiesType";
import { isAValidPassword } from "./PasswordComponent";

const LITE_PROFS = ["student", "medical_assistant"];

export function getSpecFromProfession(
  prof: string,
  t: (path: string) => string
) {
  const values: string[] = SpecialityType[prof as keyof object];
  if (values) {
    const resp = values.map((element: string) => {
      return {
        value: element,
        label: t(`speciality.${element}`),
      };
    });
    return resp ? resp : [];
  }
  return [];
}

function required(
  valueRequired: string | number | undefined,
  t: (path: string) => string
) {
  return !!valueRequired ? null : t("form.error.required");
}

function isEmailValid(email: string) {
  return email.match(
    /^[a-zA-Z\d_+-]+(?:\.[a-zA-Z\d_+-]+)*@(?:[a-zA-Z\d-]+\.)+[a-zA-Z]{2,7}$/
  );
}

export function getValidateRules(
  t: (path: string) => string,
  isSimple: boolean,
  phoneIsValid: boolean,
  sourceSite: SourceSiteType,
  register: boolean
) {
  const isTabcellSource = sourceSite === SourceSiteType.TABCELL;
  return {
    firstname: (value: string | undefined) => {
      const isFirstNameIsValid = !value?.includes("_") && !value?.includes(".");
      return isFirstNameIsValid ? null : t("form.firstname.error");
    },
    lastname: (value: string | undefined) => {
      const isLastNameIsValid = !value?.includes("_") && !value?.includes(".");
      return isLastNameIsValid ? null : t("form.lastname.error");
    },
    email: (value: string | undefined) => {
      const isEmailIsValid = value?.match(
        /^[a-zA-Z\d_+-]+(?:\.[a-zA-Z\d_+-]+)*@(?:[a-zA-Z\d-]+\.)+[a-zA-Z]{2,7}$/
      );
      return isEmailIsValid ? null : t("form.email.error");
    },
    password: (value: string | undefined, values: User) => {
      if (!register) return null;
      if (isAValidPassword(value, values.firstname, values.lastname) === 100) {
        return null;
      }
      return t("form.errorPassword");
    },
    confirmPassword: (value: string | undefined, values: User) => {
      if (!register) return null;
      if (value === values.password) {
        return null;
      }
      return t("form.errorConfirmPassword");
    },
    phone_number: (value: string | undefined) => {
      if (isSimple) {
        return null;
      }
      if (!value) {
        return t("form.error.required");
      }
      if (!phoneIsValid) {
        return t("form.phone_number.error");
      }
      return null;
    },
    country: (value: string | undefined) => {
      return required(value, t);
    },
    profession: (value: string | undefined) => {
      return required(value, t);
    },
    specialty: (value: string | undefined, allValues: User) => {
      if (!allValues.profession) return null;
      const specialities = getSpecFromProfession(allValues.profession, t);
      const valueIsNotEmpty = !value || value === "";
      const specialitiesHasUseFul = specialities.length > 1;
      if (!isTabcellSource && valueIsNotEmpty && specialitiesHasUseFul) {
        return t("form.error.required");
      } else {
        return null;
      }
    },
    payment_certificates: (value: Array<Blob> | undefined, allValues: User) => {
      const valueIsNotEmpty = value && value.length > 0;
      const isASimpleRequest =
        LITE_PROFS.includes(allValues.profession) || isSimple;
      const userWantBankTransferMethod =
        allValues.payment_methods === PaymentMethodType.BANK_TRANSFER;
      if (valueIsNotEmpty) return null;
      if (isASimpleRequest) return null;
      if (userWantBankTransferMethod) return t("form.error.required");
      return null;
    },
    ribs: (value: Array<Blob> | undefined, allValues: User) => {
      const valueIsNotEmpty = value && value.length > 0;
      const isASimpleRequest =
        LITE_PROFS.includes(allValues.profession) || isSimple;
      if (valueIsNotEmpty) return null;
      if (isASimpleRequest) return null;
      return t("form.error.required");
    },
    professional_code: (value: string | undefined, allValues: User) => {
      const isFRCountry = allValues.country === "FR";
      const validProfessionalCodeFR = value?.match(/^(\d{11})$/);
      const isLiteProfession = LITE_PROFS.includes(allValues.profession);
      if (allValues.profession === ProfessionType.OTHER) return null;
      if (isFRCountry && validProfessionalCodeFR) return null;
      if (isFRCountry && isLiteProfession) return null;
      if (isFRCountry) return t("form.professional_code.format");
      return null;
    },
    company_registration_number: (
      value: string | undefined,
      allValues: User
    ) => {
      const valueIsNotEmpty = value && value !== "";
      const isFRCountry = allValues.country === "FR";
      const validCRNFR = value?.match(/^(\d{14})$/);
      const isLiteProfession = LITE_PROFS.includes(allValues.profession);
      if (valueIsNotEmpty && isFRCountry) {
        if (validCRNFR) return null;
        if (isLiteProfession) return null;
        return t("form.company_registration_number.format");
      }
      return null;
    },
    delivery_email: (value: string | undefined, allValues: User) => {
      const isASimpleRequest =
        LITE_PROFS.includes(allValues.profession) || isSimple;
      if (isASimpleRequest) return null;
      if (!value) return t("form.email.error");
      if (isEmailValid(value)) return null;
      return t("form.email.error");
    },
    payment_methods: (value: string | undefined, allValues: User) => {
      const isASimpleRequest = allValues.profession === "student" || isSimple;
      const valueIsNotEmpty = value && value !== "";
      if (isASimpleRequest || valueIsNotEmpty) return null;
      return t("form.error.required");
    },
  };
}
