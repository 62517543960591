import { MantineThemeOverride } from "@mantine/core";

import { oralCareTheme, pf4MedTheme, defaultTheme } from "./themes";

export const getTheme = (
  themeName: string
): { name: string; object: MantineThemeOverride } => {
  const defaultObject = { name: "default", object: defaultTheme };
  const themes = [
    { name: "pfoc", object: oralCareTheme },
    { name: "pf4med", object: pf4MedTheme },
    { name: "tabcell", object: defaultTheme },
    { ...defaultObject },
  ];

  const theme = themes.find((currentTheme) => currentTheme.name === themeName);

  return theme || defaultObject;
};
