import { useState, useEffect } from "react";
import axios from "axios";
import { authStore } from "./AuthStore";
import { useSearchParams } from "react-router-dom";

const interceptorPrefixes: string[] = process.env.REACT_APP_INTERCEPTOR_PREFIXES
  ? process.env.REACT_APP_INTERCEPTOR_PREFIXES.split(",")
  : [];
const apiTimeout = 10000;

function isInPrefixes(url: string | undefined) {
  let result = false;
  if (url) {
    interceptorPrefixes.some((element) => {
      if (url.startsWith(element)) {
        result = true;
        return true;
      }
    });
  }
  return result;
}

export function Interceptor() {
  const [requestInterceptor, setRequestInterceptor] = useState<
    number | undefined
  >(undefined);
  const [searchParams] = useSearchParams();

  const addRequestInterceptor = () => {
    // Add a request interceptor to add Headers and some default
    const requestInterceptorAxios = axios.interceptors.request.use(function (
      config
    ) {
      const accessToken = authStore.accessToken;
      if (isInPrefixes(config.url)) {
        config.timeout = apiTimeout;
        if (config.headers === undefined) {
          config.headers = {};
        }
        config.headers["Content-Type"] = "application/json";
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
      }
      return config;
    });
    setRequestInterceptor(requestInterceptorAxios);
  };

  const removeRequestInterceptor = () => {
    if (requestInterceptor) {
      axios.interceptors.request.eject(requestInterceptor);
      setRequestInterceptor(undefined);
    }
  };

  useEffect(() => {
    addRequestInterceptor();
    return () => {
      removeRequestInterceptor();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}
