import { TextInput } from "@mantine/core";

import { useIntlHelper } from "../../utils/misc";
import { FormEvent } from "react";

type AddressFormProps = {
  inputKey: string;
  getInputProps: (key: string) => any;
  disabled?: boolean;
  setField: any;
};

const AddressForm = ({
  inputKey,
  getInputProps,
  disabled,
  setField,
}: AddressFormProps) => {
  const t = useIntlHelper();
  const { value } = getInputProps(inputKey);

  const onChange = (key: string, evt: FormEvent<HTMLInputElement>) => {
    setField(inputKey, { ...value, [key]: evt.currentTarget.value });
  };

  const globalCountry = getInputProps("country");

  return (
    <>
      <TextInput
        value={value?.address}
        onChange={(evt) => onChange("address", evt)}
        label={t("form.address")}
        disabled={disabled}
        required
        autoComplete="address-line1"
        sx={{ color: disabled ? "#333333" : "inherit" }}
      />
      <TextInput
        value={value?.additionalAddress}
        onChange={(evt) => onChange("additionalAddress", evt)}
        label={t("form.additionalAddress")}
        disabled={disabled}
        autoComplete="address-line2"
        sx={{ color: disabled ? "#333333" : "inherit" }}
      />
      <TextInput
        value={value?.lieudit}
        onChange={(evt) => onChange("lieudit", evt)}
        label={t("form.lieudit")}
        disabled={disabled}
        autoComplete="address-line3"
        sx={{ color: disabled ? "#333333" : "inherit" }}
      />
      <TextInput
        value={value?.postalCode}
        onChange={(evt) => onChange("postalCode", evt)}
        label={t("form.postalCode")}
        disabled={disabled}
        required
        autoComplete="postal-code"
        sx={{ color: disabled ? "#333333" : "inherit" }}
      />
      <TextInput
        value={value?.city}
        onChange={(evt) => onChange("city", evt)}
        label={t("form.city")}
        disabled={disabled}
        required
        autoComplete="address-level2"
        sx={{ color: disabled ? "#333333" : "inherit" }}
      />
      <TextInput
        value={
          value?.country
            ? value?.country
            : globalCountry?.value
            ? globalCountry?.value
            : ""
        }
        onChange={(evt) => onChange("country", evt)}
        label={t("form.country")}
        disabled={
          disabled ||
          (globalCountry && globalCountry.value && globalCountry.value !== "")
            ? true
            : false
        }
        required
        sx={{ color: disabled ? "#333333" : "inherit" }}
        autoComplete="country-name"
      />
      <TextInput
        value={value?.region}
        onChange={(evt) => onChange("region", evt)}
        label={t("form.region")}
        disabled={disabled}
        autoComplete="address-level1"
        sx={{ color: disabled ? "#333333" : "inherit" }}
      />
    </>
  );
};

export default AddressForm;
