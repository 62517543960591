import { Title, Header, MantineProvider } from "@mantine/core";
import { defaultTheme } from "../..";

const DefaultHeader = () => (
  <MantineProvider theme={defaultTheme}>
    <Header
      height={60}
      p="xs"
      sx={(theme) => ({
        display: "flex",
        backgroundColor: theme.colors.primary[1],
        color: theme.colors.primary[8],
        justifyContent: "space-between",
        alignItems: "center",
      })}
    >
      <Title order={4}>PFID</Title>
    </Header>
  </MantineProvider>
);

export default DefaultHeader;
