import {
  CountryType,
  HcpAccountResponse,
  HcpAccountUpdate,
  HcpCreateByHcp,
  HcpRequestBase,
  HcpRequestResponse,
  ProfessionType,
  SourceSiteType,
  HcpAccountDraftResponse,
  StatusAccountType,
} from "../client";
import { Address as AddressType } from "../client/models/Address";
import { Address, User } from "../components/UserProfileForm/UserProfileForm";
import { encryptPassword } from "../components/UserProfileForm/PasswordComponent";

export const enumToOptions = (enumeration: any, trans: any, prefix: string) => {
  const options = [{ value: "", label: "" }];
  Object.keys(enumeration).forEach((key) => {
    options.push({
      label: trans(`${prefix}${enumeration[key].toLowerCase()}`),
      value: enumeration[key],
    });
  });
  return options;
};

export const dateUTCToString = (date: string | undefined, locale?: string) => {
  if (!date) return "";
  const dateObject = new Date(date);
  return dateObject.toLocaleDateString(locale ?? "fr");
};

export const addressTypeToAddressForm = (
  addr: AddressType | undefined
): Address => {
  if (!addr)
    return {
      additionalAddress: "",
      address: "",
      city: "",
      country: "",
      postalCode: "",
      lieudit: "",
      region: "",
    };
  const addressArray = addr.street_address.split("\n");
  return {
    address: addressArray[0] ?? "",
    additionalAddress: addressArray[1] ?? "",
    lieudit: addressArray[2] ?? "",
    city: addr.locality ?? "",
    country: addr.country ?? "",
    postalCode: addr.postal_code ?? "",
    region: addr.region ?? "",
  };
};

export const addressFormToAddressType = (
  address?: Address
): AddressType | undefined => {
  if (!address) return undefined;
  let streetAddress = address.address;
  streetAddress += address.additionalAddress
    ? "\n" + address.additionalAddress
    : "";
  streetAddress += address.lieudit ? "\n" + address.lieudit : "";

  return {
    formatted: `${streetAddress}\n${address.postalCode}\n${address.city}\n${address.country}`,
    street_address: streetAddress,
    locality: address.city,
    region: address.region,
    postal_code: address.postalCode,
    country: address.country,
  };
};

export const formatData = (
  data: HcpRequestResponse | HcpAccountResponse
): User => {
  const graduateDate = data.graduate_date
    ? new Date(data.graduate_date).toISOString()
    : undefined;
  return {
    ...data,
    delivery_address: addressTypeToAddressForm(data.delivery_address),
    professional_address: addressTypeToAddressForm(data.address),
    billing_address: addressTypeToAddressForm(data.billing_address),
    profession: data.profession ?? "",
    country: data.country ?? "",
    source_site: data.source_site ?? SourceSiteType.PORTAL,
    specialty: data.specialty ?? "",
    graduate_date: graduateDate,
    payment_methods:
      data.payment_methods && data.payment_methods.length > 0
        ? data.payment_methods[0]
        : "",
  };
};

export const userToHcpCreateByHcp = (
  data: User
): HcpCreateByHcp | HcpRequestBase => {
  const {
    consent,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    payment_certificates,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    professional_certificates,
    ribs,
    isProfessionalAsBilling,
    isDeliveryAsProfessional,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    delivery_address,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    billing_address,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    professional_address,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    payment_methods,
    confirmPassword,
    password,
    ...rest
  } = data;
  const graduateDate = data.graduate_date
    ? new Date(data.graduate_date).getTime()
    : undefined;

  return {
    ...rest,
    // source_site: SourceSiteType.PFOC,
    firstname: data.firstname ?? undefined,
    lastname: data.lastname ?? undefined,
    email: data.email ?? undefined,
    profession:
      data.profession === "" ? ProfessionType.DOCTOR : data.profession,
    country: data.country === "" ? CountryType.FR : data.country,
    specialty: data.specialty,
    address: addressFormToAddressType(data.professional_address),
    delivery_address: addressFormToAddressType(
      data.isDeliveryAsProfessional
        ? data.delivery_address
        : data.professional_address
    ),
    billing_address: addressFormToAddressType(
      data.isProfessionalAsBilling
        ? data.billing_address
        : data.professional_address
    ),
    graduate_date: data.profession !== "student" ? undefined : graduateDate,
    // @ts-expect-error test
    payment_methods: data.payment_methods ? [payment_methods] : [],
    password: password ? encryptPassword(password) : undefined,
  };
};

export const userToHcpAccountUpdate = (
  data: HcpRequestBase,
  oldData: HcpRequestBase
): HcpAccountUpdate => {
  const keysToUpdate = [];
  const dataToUpdate: HcpAccountUpdate = {};
  Object.keys(oldData).forEach((key) => {
    // @ts-expect-error the keys are good types
    if (data[key] !== oldData[key]) {
      keysToUpdate.push(key);
      // @ts-expect-error the keys are good types
      dataToUpdate[key] = data[key];
    }
  });
  const keysToCheck = [
    "firstname",
    "lastname",
    "email",
    "profession",
    "specialty",
    "country",
    "phone_number",
    "professional_code",
    "corporate_name",
    "company_registration_number",
    "delivery_address",
    "address",
    "billing_address",
    "delivery_email",
    "payment_methods",
    "delivery_preference",
  ];

  if (keysToCheck.some((key) => keysToCheck.includes(key))) {
    dataToUpdate.status = StatusAccountType.PENDING;
  }

  return dataToUpdate;
};

export const hcpAccountDraftResponseToUser = (
  accountData: HcpAccountResponse,
  data: HcpAccountDraftResponse
): User => {
  // console.log(" -- hcpAccountDraftResponseToUser --: DRAFT DATA ", data)
  const defaultUser = formatData(accountData);
  const graduateDate = data.graduate_date
    ? new Date(data.graduate_date).toISOString()
    : undefined;
  const resp = {
    ...defaultUser,
    ...data,
    address: data.address ?? defaultUser.address,
    business_partner_id:
      data.business_partner_id ?? defaultUser.business_partner_id,
    comments: data.comments ?? defaultUser.comments,
    company_registration_number:
      data.company_registration_number ??
      defaultUser.company_registration_number,
    corporate_name: data.corporate_name ?? defaultUser.corporate_name,
    country: data.country ?? defaultUser.country,
    created_at: data.created_at ?? defaultUser.created_at,
    delivery_email: data.delivery_email ?? defaultUser.delivery_email,
    delivery_preference:
      data.delivery_preference ?? defaultUser.delivery_preference,
    email: data.email ?? defaultUser.email,
    firstname: data.firstname ?? defaultUser.firstname,
    followed_by: data.followed_by ?? defaultUser.followed_by,
    graduateDate: data.graduate_date ?? defaultUser.graduate_date,
    lastname: data.lastname ?? defaultUser.lastname,
    payment_certificate_ids:
      data.payment_certificate_ids ?? defaultUser.payment_certificate_ids,
    phone_number: data.phone_number ?? defaultUser.phone_number,
    profession: data.profession ?? defaultUser.profession,
    professional_certificate_ids:
      data.professional_certificate_ids ??
      defaultUser.professional_certificate_ids,
    professional_code: data.professional_code ?? defaultUser.professional_code,
    rib_ids: data.rib_ids ?? defaultUser.rib_ids,
    specialty: data.specialty ?? defaultUser.specialty,
    delivery_address: data?.delivery_address
      ? addressTypeToAddressForm(data?.delivery_address)
      : defaultUser.delivery_address,
    professional_address: data?.address
      ? addressTypeToAddressForm(data?.address)
      : defaultUser.professional_address,
    billing_address: data?.billing_address
      ? addressTypeToAddressForm(data?.billing_address)
      : defaultUser.billing_address,
    graduate_date: graduateDate,
    payment_methods:
      data?.payment_methods && data?.payment_methods.length > 0
        ? data?.payment_methods[0]
        : defaultUser.payment_methods,
  };

  console.log(" === RESP = ", resp);

  return resp;
};

export function getEnumKeyByEnumValue<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string,
  defaultVal: keyof T
): keyof T {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
  return keys.length > 0 ? keys[0] : defaultVal;
}
