import { createContext, useContext } from "react";
import { makeAutoObservable, action } from "mobx";

/*
  TODO Move it to the OIDCComponent
 */

export interface UserData {
  sub?: string;
  country?: string;
  roles?: Array<string>;
  groups?: Array<string>;
  preferred_username?: string;
  given_name?: string;
  upn?: string;
  office_name?: string;
  employee_type?: string;
  auth_time?: number;
  family_name?: string;
  email?: string;
  account_id: string;
}

export class AuthStore {
  public accessToken?: string;

  public userData: UserData = { roles: [], account_id: "" };

  constructor() {
    makeAutoObservable(this);
  }

  @action setAccessToken(accessToken: string | undefined) {
    this.accessToken = accessToken;
  }

  @action setUserData(userData: UserData) {
    this.userData = userData;
  }
}

export const authStore: AuthStore = new AuthStore();
export const authStoreContext = createContext({ authStore: authStore });
export const useAuthStore = () => useContext(authStoreContext);
