import { useEffect, useState, useMemo, useCallback } from "react";
import {
  Box,
  Button,
  Grid,
  Select,
  Space,
  Text,
  TextInput,
  Group,
  Title,
  Checkbox,
  Tooltip,
  LoadingOverlay,
  createStyles,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useForm } from "@mantine/form";
import { RichTextEditor } from "@mantine/rte";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import PhoneInput from "react-phone-input-2";
import { IconAlertCircle } from "@tabler/icons";
import formData from "./formData.json";

import { getValidateRules, getSpecFromProfession } from "./form";
import { GetInputs, useStylesForm } from "./GetInputs";
import { getProfessions } from "./themeProfession";
import {
  CountryType,
  DenodoService,
  HcpCreateByHcp,
  HcpRequestResponse,
  ProfessionType,
  RequestsSendByHcpService,
  RequestsSendByPfInternalPeopleService,
  RequestStatusType,
  SourceSiteType,
} from "../../client";
import { enumToOptions, userToHcpCreateByHcp } from "../../utils/fn";
import { useIntlHelper } from "../../utils/misc";
import {
  createLoading,
  updateIntoError,
  updateIntoSuccess,
} from "../../utils/notifications";
import PasswordComponent from "./PasswordComponent";

const notMandatoryProfessions: Record<string, string[]> = {
  FR: ["student", "other", "medical_assistant"]
};

export const useStylesButton = createStyles(() => ({
  button: {
    backgroundColor: "#031B3D"
  }
}));

export type Address = {
  additionalAddress: string;
  address: string;
  city: string;
  country: string;
  postalCode: string;
  lieudit: string;
  region: string;
};

export type User = Omit<
  HcpRequestResponse,
  | "request_status"
  | "profession"
  | "country"
  | "request_id"
  | "specialty"
  | "delivery_address"
  | "professional_address"
  | "billing_address"
  | "graduate_date"
  | "payment_methods"
> & {
  profession: ProfessionType | "";
  country: CountryType | "";
  specialty: string;
  consent?: boolean;
  request_id?: string;
  request_status?: RequestStatusType;
  professional_certificates?: Array<Blob>;
  ribs?: Array<Blob>;
  payment_certificates?: Array<Blob>;
  isDeliveryAsProfessional?: boolean;
  isProfessionalAsBilling?: boolean;
  delivery_address?: Address;
  professional_address?: Address;
  billing_address?: Address;
  graduate_date?: string | undefined;
  payment_methods?: string | undefined;
  comments?: string | undefined;
  captchaToken?: string | undefined;
  password?: string | undefined;
  confirmPassword?: string | undefined;
  status?: string | undefined;
};

interface UserProfileProps {
  readOnly?: boolean;
  data?: User;
  redirect?: string;
  saveForm?: (
    user: User,
    professional_certificates?: Array<Blob>,
    ribs?: Array<Blob>,
    payment_certificates?: Array<Blob>
  ) => void;
  onSubmit?: (values: User) => void;
  register?: boolean;
  simple?: boolean;
  displayComments?: boolean;
  country?: CountryType | "";
  profession?: ProfessionType | "";
  removeFile?: (url: string) => void;
  vm?: boolean; // True if form from vmapp
  sourceTheme?: string;
  sourceSite: SourceSiteType;
  fieldsToDisplay?: string[];
}

export const UserProfileForm = ({
  readOnly,
  data,
  redirect,
  saveForm,
  onSubmit,
  register,
  simple,
  displayComments,
  sourceTheme,
  sourceSite,
  country = "",
  removeFile,
  profession = "",
  vm = false,
  fieldsToDisplay,
}: UserProfileProps) => {
  const defaultInputs = simple
    ? register
      ? formData.default.simple.request
      : formData.default.simple.profile
    : register
    ? formData.default.request
    : formData.default.profile;
  const inputKeys =
    // @ts-expect-error We not use all country and professions Keys
    formData.countries?.[country]?.[profession] ?? defaultInputs;

  const { classes } = useStylesForm();
  const styleButton = useStylesButton();
  const t = useIntlHelper();
  const [isSimple, setIsSimple] = useState(simple);
  const [phoneIsValid, setPhoneIsValid] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [comments, onChangeComments] = useState(data?.comments ?? "<p></p>");
  const [visible, setVisible] = useState(false);

  const initialUserForm: User = useMemo(() => {
    return (
      data ?? {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        profession: profession ?? "",
        country: country ?? "",
        source_site: sourceSite ?? SourceSiteType.PORTAL,
        consent: false,
        specialty: "",
        captchaToken: "",
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const form = useForm<User>({
    initialValues: initialUserForm,
    validateInputOnChange: true,
    validate: getValidateRules(
      t,
      isSimple ?? false,
      phoneIsValid,
      sourceSite,
      register ?? false
    ),
  });

  const professions = useMemo(
    () => enumToOptions(getProfessions(sourceSite), t, "profession."),
    [sourceSite, t]
  );

  const isFieldIsVisible = useCallback(
    (field: string) => {
      if (!fieldsToDisplay) return true;
      return fieldsToDisplay.includes(field);
    },
    [fieldsToDisplay]
  );

  const specialitiesIsVisible = useMemo(() => {
    const specialities = getSpecFromProfession(form.values.profession, t);
    return (
      form.values.profession !== "" &&
      sourceSite !== SourceSiteType.TABCELL &&
      specialities.length > 1
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.profession, sourceSite]);

  useEffect(() => {
    if (data) {
      form.setValues(data);
      onChangeComments(data?.comments || "<p><br /></p>");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  async function createAccount(values: User) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { professional_certificates, ribs, payment_certificates } = values;

    // Copy country name to addresses
    if (values.address && !values.address.country) {
      values.address.country = values.country;
    }
    if (values.professional_address && !values.professional_address.country) {
      values.professional_address.country = values.country;
    }
    if (values.delivery_address && !values.delivery_address.country) {
      values.delivery_address.country = values.country;
    }
    if (values.billing_address && !values.billing_address.country) {
      values.billing_address.country = values.country;
    }
    const hcpData = userToHcpCreateByHcp(values) as HcpCreateByHcp;
    const idNotification = "createAccount";

    if (!vm) {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }
      hcpData.captchaToken = await executeRecaptcha("submit");
      createLoading(idNotification, t("toast.loading"));
      RequestsSendByHcpService.createHcpAccountByHcpHcpRequestPost({
        hcp: hcpData,
        professional_certificates,
        ribs,
        payment_certificates,
      })
        .then(() => {
          updateIntoSuccess(idNotification, t("toast.requestAsked.success"));
          if (redirect) {
            document.location.href = redirect;
          }
        })
        .catch((error) => {
          updateIntoError(idNotification, error, t);
        });
    } else {
      createLoading(idNotification, t("toast.loading"));
      RequestsSendByPfInternalPeopleService.createHcpAccountByVmVmRequestPost({
        hcp: hcpData,
        professional_certificates,
        ribs,
        payment_certificates,
      })
        .then(() => {
          updateIntoSuccess(idNotification, t("toast.createAccountVM.success"));
        })
        .catch((error) => {
          updateIntoError(idNotification, error, t);
        });
    }
  }

  function getCountryData() {
    let countryData = enumToOptions(CountryType, t, "country.");
    countryData = countryData.sort((a, b) => {
      const aLabel = a.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      const bLabel = b.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      if (a.value === "Autres" || bLabel === "Autres") return 0;
      if (aLabel > bLabel) return 1;
      if (aLabel < bLabel) return -1;
      return 0;
    });
    return countryData;
  }

  function validateProfessionalCode(
    value: string | undefined,
    allValues: User
  ) {
    const isFRCountry = allValues.country === "FR";
    const validProfessionalCodeFR = value?.match(/^(\d{11})$/);
    if (isFRCountry && validProfessionalCodeFR) return null;
    if (isFRCountry) return t("form.professional_code.format");
    return null;
  }

  function getInfosFromProfessionalCode(
    code: string | undefined,
    country_code: string | undefined,
    allValues: User
  ) {
    if (!code) return;
    if (country_code !== "FR") return; // TODO - remove when we have RPPS rules for other countries
    if (validateProfessionalCode(code, allValues) === null) {
      setVisible(true);
      DenodoService.findHcpByRegistrationNumberAndCountryRefHcpCountryRegistrationNumberGet(
        code,
        country_code
      )
        .then((res) => {
          form.setValues({
            firstname: res.first_name,
            lastname: res.last_name,
          });
          setVisible(false);
        })
        .catch((error) => {
          setVisible(false);
          form.setValues({
            firstname: "",
            lastname: "",
          });
          let message = "";
          if (error.status === 404)
            message = t("form.professional_code.RPPS_not_found_error");
          else message = t("form.professional_code.RPPS_service_error");
          showNotification({
            message,
            color: "red",
            id: "createAccount",
            autoClose: 8000,
          });
        });
    }
  }

  return (
    <>
      <Box pos="relative">
        <LoadingOverlay visible={visible} zIndex={1000} />
        <form onSubmit={form.onSubmit(onSubmit ?? createAccount)}>
          <Title order={2} color="primary">
            {t("form.myData")}
          </Title>
          {isFieldIsVisible("country") && (
            <>
              <Select
                label={t("form.pays")}
                {...form.getInputProps("country")}
                data={getCountryData()}
                required
                searchable
                disabled={readOnly || !!country}
              />
            </>
          )}
          {isFieldIsVisible("country") && form.values.country !== "" && (
            <>
              <Space h="md" />
              <Select
                label={t("form.profession")}
                {...form.getInputProps("profession")}
                data={professions}
                required
                disabled={readOnly || !!profession}
              />
            </>
          )}
          {isFieldIsVisible("specialty") && specialitiesIsVisible && (
            <>
              <Space h="md" />
              <Select
                label={t("form.specialty")}
                {...form.getInputProps("specialty")}
                data={getSpecFromProfession(form.values.profession, t)}
                required
                disabled={readOnly}
              />
            </>
          )}
          {inputKeys.includes("professional_code") && (
            <>
              {isSimple &&
                form.values.profession != "" &&
                isFieldIsVisible("professional_code") && (
                  <TextInput
                    {...form.getInputProps("professional_code")}
                    label={t("form.professional_code")}
                    required={
                      form.values.country in notMandatoryProfessions
                        ? notMandatoryProfessions[form.values.country].some(
                            (x) => x === form.values.profession
                          )
                          ? false
                          : true
                        : true
                    }
                    className={classes.space}
                    disabled={readOnly}
                    rightSection={
                      <Tooltip
                        label={t("tooltip.rpps")}
                        position="top-end"
                        multiline
                        width={220}
                        withArrow
                      >
                        <div>
                          <IconAlertCircle
                            size={18}
                            style={{ display: "block", opacity: 0.5 }}
                          />
                        </div>
                      </Tooltip>
                    }
                    sx={{ color: readOnly ? "#333333" : "inherit" }}
                    onChange={(e) => {
                      getInfosFromProfessionalCode(
                        e.target.value,
                        form.values.country,
                        form.values
                      );
                      form.setValues({
                        professional_code: e.target.value,
                      });
                    }}
                  />
                )}
            </>
          )}
          <GetInputs
            getInputProps={form.getInputProps}
            values={form.values}
            readOnly={readOnly}
            register={register}
            setField={form.setFieldValue}
            simple={isSimple}
            removeFile={removeFile}
            sourceTheme={sourceTheme}
            isFieldIsVisible={isFieldIsVisible}
            isVisible={
              form.values.country !== "" &&
              form.values.profession !== "" &&
              (!specialitiesIsVisible ||
                (specialitiesIsVisible && form.values.specialty !== ""))
            }
            isAdminEditPage={(fieldsToDisplay?.length ?? 0) > 0}
          />
          <Space h="md" />
          {isFieldIsVisible("firstname") && (
            <>
              <TextInput
                label={t("form.prenom")}
                {...form.getInputProps("firstname")}
                required
                className={
                  form.getInputProps("firstname").value !== ""
                    ? "field-updated"
                    : ""
                }
                disabled={readOnly}
              />
              <Space h="md" />
            </>
          )}
          {isFieldIsVisible("lastname") && (
            <>
              <TextInput
                label={t("form.nom")}
                {...form.getInputProps("lastname")}
                required
                disabled={readOnly}
              />
              <Space h="md" />
            </>
          )}
          {isFieldIsVisible("email") && (
            <TextInput
              label={t("form.email")}
              {...form.getInputProps("email")}
              required
              disabled={readOnly || !register}
            />
          )}
          {register && <PasswordComponent form={form} />}
          {isFieldIsVisible("phone_number") && !simple && (
            <>
              <Space h="md" />
              <Group>
                <Text className={classes.inputLabel}>
                  {t("form.phone_number")}
                  {!isSimple && (
                    <Box
                      component="span"
                      sx={{ fontSize: 14, color: "#f03e3e" }}
                    >
                      {" "}
                      *
                    </Box>
                  )}
                </Text>
              </Group>
              <PhoneInput
                country={"fr"}
                value={form.getInputProps("phone_number").value}
                onChange={form.getInputProps("phone_number").onChange}
                disabled={readOnly}
                inputProps={{ required: true, style: { width: "100%" } }}
                isValid={(value, countryObject) => {
                  // @ts-expect-error format exist
                  if (value === countryObject.countryCode && isSimple) {
                    setPhoneIsValid(() => true);
                    return true;
                  }
                  // @ts-expect-error format exist
                  let countryFormat = countryObject.format;
                  // @ts-expect-error countryCode exist
                  if (countryObject.countryCode === "689") {
                    countryFormat = "+... .. .. .. ..";
                  }
                  // @ts-expect-error countryCode exist
                  if (countryObject.countryCode === "385") {
                    countryFormat = "+... ... ... ...";
                  }
                  const countryFormatSimplify: string = countryFormat.replace(
                    /[ ()+-]/g,
                    ""
                  );
                  if (value.length === countryFormatSimplify.length) {
                    setPhoneIsValid(() => true);
                    return true;
                  } else {
                    if (
                      countryFormat === "+.. ... ... ... ... .." ||
                      countryFormat === "+... ... ... ... ... .."
                    ) {
                      const isValid: boolean =
                        value.length === countryFormat.length - 1;
                      setPhoneIsValid(() => isValid);
                      return isValid;
                    }
                    setPhoneIsValid(false);
                    return false;
                  }
                }}
              />
              {form.getInputProps("phone_number").error && !phoneIsValid && (
                <Text
                  sx={(theme) => ({
                    fontSize: 12,
                    color: theme.colors.red[6],
                  })}
                >
                  {form.getInputProps("phone_number").error}
                </Text>
              )}
            </>
          )}
          {displayComments && (
            <>
              <Space h="md" />
              <Box>
                <Text className={classes.inputLabel}>
                  {t("form.comment_label")}
                </Text>
                <RichTextEditor
                  sticky={false}
                  value={comments}
                  onChange={onChangeComments}
                  sx={{ height: "180px", overflow: "auto" }}
                  id="rte"
                />
              </Box>
            </>
          )}
          {!isSimple && displayComments && (
            <>
              <Space h="md" />
              <TextInput
                {...form.getInputProps("business_partner_id")}
                label={t("form.business_partner_id")}
                disabled={readOnly}
              />
            </>
          )}
          {saveForm && (
            <>
              <Space h="md" />
              <Grid m={0} justify="flex-end">
                <Button
                  variant="gradient"
                  gradient={{ from: "indigo", to: "cyan" }}
                  onClick={() =>
                    saveForm({
                      ...form.values,
                      comments,
                    })
                  }
                >
                  {t("button.update")}
                </Button>
                {isSimple && (
                  <Button
                    variant="gradient"
                    gradient={{ from: "indigo", to: "cyan" }}
                    onClick={() => {
                      setIsSimple(false);
                    }}
                  >
                    {t("button.upgradeAccount")}
                  </Button>
                )}
              </Grid>
            </>
          )}

          {register && (
            <>
              <Space h="md" />
              <p style={{ fontSize: "12px" }}>{t("form.consent.p1")}</p>
              <p style={{ fontSize: "12px" }}>{t("form.consent.p2")}</p>
              <p style={{ fontSize: "12px" }}>{t("form.consent.p3")}</p>
              <p style={{ fontSize: "12px" }}>{t("form.consent.p4")}</p>
              <Space h="md" />
              <Checkbox
                {...form.getInputProps("consent")}
                label={t("form.consent.checkbox")}
              />
              <Space h="md" />
              <Button
                styles={{
                  root: {
                    ":hover": { backgroundColor: "#1c7ed6" },
                  },
                }}
                type="submit"
                className={styleButton.classes.button}
                disabled={!form.values.consent}
              >
                {t("form.envoyer")}
              </Button>
              <Space h="xl" />
            </>
          )}
        </form>
      </Box>
    </>
  );
};
