import {
  createStyles,
  TextInput,
  Select,
  Checkbox,
  Text,
  Title,
  Space,
  Tooltip,
} from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";

import { DatePicker } from "@mantine/dates";
import "react-phone-input-2/lib/style.css";
import "dayjs/locale/fr";

import formData from "./formData.json";
import { User } from "./UserProfileForm";
import AddressForm from "../AddressForm/AddressForm";
import DropzoneInput from "../DropzoneInput/DropzoneInput";
import FilesList from "../FilesList/FilesList";
import { PaymentMethodType, ProfessionType } from "../../client";
import { enumToOptions } from "../../utils/fn";
import { useIntlHelper } from "../../utils/misc";
import { useAuthStore } from "../Header/AuthStore";

const notMandatoryProfessions: Record<string, string[]> = {
  FR: ["student", "other", "medical_assistant"]
};

export const useStylesForm = createStyles(() => ({
  inputLabel: {
    marginBottom: 4,
    fontSize: 14,
    fontWeight: 500,
    marginTop: 16,
  },
  space: {
    marginTop: 16,
  },
  inputTel: {
    width: "100%",
  },
}));

type GetInputProps = {
  values: User;
  getInputProps: any;
  setField: any;
  readOnly?: boolean;
  register?: boolean;
  simple?: boolean;
  removeFile?: (url: string) => void;
  sourceTheme?: string;
  isVisible: boolean;
  isFieldIsVisible: (nameField: string) => boolean;
  isAdminEditPage?: boolean;
};

export const GetInputs = ({
  getInputProps,
  readOnly,
  register,
  values,
  setField,
  simple,
  removeFile,
  sourceTheme,
  isVisible,
  isFieldIsVisible,
  isAdminEditPage = false,
}: GetInputProps) => {
  const t = useIntlHelper();
  const { authStore } = useAuthStore();
  const { classes } = useStylesForm();
  const {
    country,
    profession,
    isDeliveryAsProfessional,
    isProfessionalAsBilling,
  } = values;
  if (!isVisible) return null;
  const defaultInputs = simple
    ? register
      ? formData.default.simple.request
      : formData.default.simple.profile
    : register
    ? formData.default.request
    : formData.default.profile;
  const inputKeys =
    // @ts-expect-error We not use all country and professions Keys
    formData.countries?.[country]?.[profession] ?? defaultInputs;
  const isSupport =
    authStore.userData.roles &&
    authStore.userData.roles.includes("APP_INT_PFID_BACKOFFICE");

  return (
    <>
      {inputKeys.includes("graduate_date") &&
        isFieldIsVisible("graduate_date") && (
          <DatePicker
            {...getInputProps("graduate_date")}
            label={t("form.graduate_date")}
            required
            className={classes.space}
            disabled={readOnly}
            inputFormat="DD/MM/YYYY"
            locale="fr"
            value={new Date(getInputProps("graduate_date").value || Date.now())}
            excludeDate={(date) => {
              const currentDay = new Date(Date.now());
              currentDay.setHours(0, 0, 0, 0);
              return date.getTime() < currentDay.getTime();
            }}
            sx={{ color: readOnly ? "#333333" : "inherit" }}
          />
        )}
      {inputKeys.includes("professional_code") && (
        <>
          {!simple && isFieldIsVisible("professional_code") && (
            <TextInput
              {...getInputProps("professional_code")}
              label={t("form.professional_code")}
              required={
                values.country in notMandatoryProfessions
                  ? notMandatoryProfessions[values.country].some(
                      (x) => x === values.profession
                    )
                    ? false
                    : true
                  : true
              }
              className={classes.space}
              disabled={readOnly}
              rightSection={
                <Tooltip
                  label={t("tooltip.rpps")}
                  position="top-end"
                  multiline
                  width={220}
                  withArrow
                >
                  <div>
                    <IconAlertCircle
                      size={18}
                      style={{ display: "block", opacity: 0.5 }}
                    />
                  </div>
                </Tooltip>
              }
              sx={{ color: readOnly ? "#333333" : "inherit" }}
            />
          )}
          {!simple && isFieldIsVisible("professional_certificate_ids") && (
            <>
              {!readOnly ? (
                <>
                  <FilesList
                    inputKey="professional_certificate_ids"
                    value={getInputProps("professional_certificate_ids").value}
                    onRemove={isSupport ? removeFile : undefined}
                  />

                  <DropzoneInput
                    getInputProps={getInputProps}
                    inputKey={"professional_certificates"}
                    disabled={readOnly}
                    tooltip="tooltip.justif"
                    required={false}
                  />
                </>
              ) : (
                <>
                  {isFieldIsVisible("professional_certificate_ids") && (
                    <>
                      <FilesList
                        inputKey="professional_certificate_ids"
                        value={
                          getInputProps("professional_certificate_ids").value
                        }
                        onRemove={isSupport ? removeFile : undefined}
                      />
                      <Space
                        h={103.7}
                        data-name="professional_certificate_ids"
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      {inputKeys.includes("corporate_name") &&
        isFieldIsVisible("corporate_name") && (
          <TextInput
            {...getInputProps("corporate_name")}
            label={t("form.corporate_name")}
            required
            className={classes.space}
            disabled={readOnly}
            sx={{ color: readOnly ? "#333333" : "inherit" }}
            rightSection={
              <Tooltip
                label={t("tooltip.corporate_name")}
                position="top-end"
                multiline
                width={220}
                withArrow
              >
                <div>
                  <IconAlertCircle
                    size={18}
                    style={{ display: "block", opacity: 0.5 }}
                  />
                </div>
              </Tooltip>
            }
          />
        )}
      {inputKeys.includes("company_registration_number") &&
        isFieldIsVisible("company_registration_number") && (
          <TextInput
            {...getInputProps("company_registration_number")}
            label={t("form.company_registration_number")}
            required
            className={classes.space}
            disabled={readOnly}
            sx={{ color: readOnly ? "#333333" : "inherit" }}
          />
        )}
      {!readOnly &&
      inputKeys.includes("ribs") &&
      isFieldIsVisible("rib_ids") ? (
        <>
          <FilesList
            inputKey="rib_ids"
            value={getInputProps("rib_ids").value}
            onRemove={isSupport ? removeFile : undefined}
          />
          <DropzoneInput
            getInputProps={getInputProps}
            inputKey={"ribs"}
            disabled={readOnly}
          />
        </>
      ) : (
        <>
          {inputKeys.includes("ribs") && isFieldIsVisible("rib_ids") && (
            <>
              <FilesList
                inputKey="rib_ids"
                value={getInputProps("rib_ids").value}
                onRemove={isSupport ? removeFile : undefined}
              />
              <Space h={103.7} data-name="rib_ids" />
            </>
          )}
        </>
      )}
      {inputKeys.includes("professional_address") || isAdminEditPage ? (
        <>
          {readOnly && <Space h="md" />}
          <Title order={2} color="primary">
            {t("form.addresses")}
          </Title>
        </>
      ) : null}
      {inputKeys.includes("professional_address") &&
        isFieldIsVisible("professional_address") && (
          <>
            <Text className={classes.inputLabel}>
              {t(`form.professional_address`)}
            </Text>
            <AddressForm
              inputKey="professional_address"
              getInputProps={getInputProps}
              disabled={readOnly}
              setField={setField}
            />
          </>
        )}
      {inputKeys.includes("delivery_address") &&
        isFieldIsVisible("delivery_address") && (
          <>
            <Text className={classes.inputLabel}>
              {t(`form.delivery_address`)}
            </Text>
            <Checkbox
              {...getInputProps("isDeliveryAsProfessional")}
              className={classes.space}
              label={t("form.isDeliveryAsProfessional")}
            />
            {isDeliveryAsProfessional && (
              <AddressForm
                inputKey="delivery_address"
                getInputProps={getInputProps}
                disabled={readOnly}
                setField={setField}
              />
            )}
          </>
        )}
      {inputKeys.includes("billing_address") &&
        isFieldIsVisible("billing_address") && (
          <>
            <Text className={classes.inputLabel}>
              {t(`form.billing_address`)}
            </Text>
            <Checkbox
              {...getInputProps("isProfessionalAsBilling")}
              className={classes.space}
              label={t("form.isProfessionalAsBilling")}
            />
            {isProfessionalAsBilling && (
              <AddressForm
                inputKey="billing_address"
                getInputProps={getInputProps}
                disabled={readOnly}
                setField={setField}
              />
            )}
          </>
        )}
      {inputKeys.includes("delivery_email") || isAdminEditPage ? (
        <>
          <Space h="md" />
          <Title order={2} color="primary">
            {t("form.preferences")}
          </Title>
        </>
      ) : null}
      {inputKeys.includes("delivery_email") &&
        isFieldIsVisible("delivery_email") && (
          <>
            <TextInput
              {...getInputProps("delivery_email")}
              label={t("form.delivery_email")}
              disabled={readOnly}
              required
              sx={{ color: readOnly ? "#333333" : "inherit" }}
            />
          </>
        )}
      {inputKeys.includes("delivery_preference") &&
        isFieldIsVisible("delivery_preference") && (
          <TextInput
            {...getInputProps("delivery_preference")}
            label={t("form.delivery_preference")}
            placeholder={t("form.delivery_preference.placeholder")}
            className={classes.space}
            disabled={readOnly}
            sx={{ color: readOnly ? "#333333" : "inherit" }}
          />
        )}
      {inputKeys.includes("payment_methods") && (
        <>
          {isFieldIsVisible("payment_methods") && (
            <Select
              {...getInputProps("payment_methods")}
              data={enumToOptions(PaymentMethodType, t, "payment.")}
              label={t("form.payment_methods")}
              className={classes.space}
              disabled={readOnly}
              sx={{ color: readOnly ? "#333333" : "inherit" }}
              required
            />
          )}
          {!readOnly &&
          isFieldIsVisible("payment_certificate_ids") &&
          getInputProps("payment_methods").value === "bank_transfer" ? (
            <>
              <DropzoneInput
                getInputProps={getInputProps}
                inputKey={"payment_certificates"}
                disabled={readOnly}
              />
              <FilesList
                inputKey="payment_certificate_ids"
                value={getInputProps("payment_certificate_ids").value}
                onRemove={isSupport ? removeFile : undefined}
              />
            </>
          ) : (
            <>
              {isFieldIsVisible("payment_certificate_ids") && (
                <>
                  <FilesList
                    inputKey="payment_certificate_ids"
                    value={getInputProps("payment_certificate_ids").value}
                    onRemove={isSupport ? removeFile : undefined}
                  />
                  <Space h={103.7} data-name="payment_certificate_ids" />
                </>
              )}
            </>
          )}
          {sourceTheme == "pfoc" ? (
            <Text className={classes.inputLabel}>
              {t(`form.payment_info_cb`)}
            </Text>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
