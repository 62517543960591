import { PropsWithChildren } from "react";
import {
  Container,
  Grid,
  Box,
  Image,
  Text,
  MediaQuery,
  createStyles
} from "@mantine/core";
import { Check } from "tabler-icons-react";
import { useIntlHelper } from "@pfid/common";
import logo from "../../assets/Logo.svg";

const useStyles = createStyles((theme) => ({
  leftContentContainer: {
    backgroundColor: "#F4F9FF",
    height: "100%",
    paddingBottom: "20px",
  },
  leftContent: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 36,
    marginTop: 80,
    fontWeight: 700,
    lineHeight: "49px",
    color: theme.colors.secondary[4],
    marginLeft: "5%",
  },
  check: {
    color: theme.colors.secondary[4],
  },
}));

type PageProps = PropsWithChildren<any>;

const Page = ({ children }: PageProps) => {
  const t = useIntlHelper();
  const { classes } = useStyles();

  return (
    <Grid m={0} gutter={30}>
      <MediaQuery smallerThan="md" styles={{ display: "none" }}>
        <Grid.Col p={0} sm={0} md={3} lg={4}>
          <Container className={classes.leftContentContainer}>
            <Box className={classes.leftContent}>
              <Image
                src={logo}
                alt="logo_pfid"
                width={188}
                sx={{ paddingTop: 100 }}
              />
              <Text className={classes.title}>{t("creation.title")}</Text>
              <Grid sx={{ marginTop: 30, marginLeft: "5%" }} gutter={26}>
                <Grid.Col span={1} sx={{ alignItems: "flex-end" }}>
                  <Check className={classes.check} />
                </Grid.Col>
                <Grid.Col span={11}>
                  <Text>{t("creation.account")}</Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Check className={classes.check} />
                </Grid.Col>
                <Grid.Col span={11}>
                  <Text>{t("creation.speed")}</Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Check className={classes.check} />
                </Grid.Col>
                <Grid.Col span={11}>
                  <Text>{t("creation.security")}</Text>
                </Grid.Col>
              </Grid>
            </Box>
          </Container>
        </Grid.Col>
      </MediaQuery>
      <Grid.Col sm={12} md={9} lg={8}>
        {children}
      </Grid.Col>
    </Grid>
  );
};

export default Page;
