/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum StatusAccountType {
    PENDING = 'PENDING',
    OK = 'OK',
}