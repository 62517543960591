import { useMemo } from "react";
import { PasswordInput, Space, List, Progress } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { JSEncrypt } from "jsencrypt";
import { User } from "./UserProfileForm";
import { useIntlHelper } from "../../utils/misc";

type PasswordComponentProps = {
  form: UseFormReturnType<User>;
};

export function isAValidPassword(
  password: string | undefined,
  firstname: string | undefined,
  lastname: string | undefined
): number {
  let currentProgress = 0;
  if (!password) return currentProgress;
  const atLeast1Upper = new RegExp("[A-Z]");
  if (atLeast1Upper.test(password)) currentProgress += 20;
  const atLeast1Lower = new RegExp("[a-z]");
  if (atLeast1Lower.test(password)) currentProgress += 20;
  const atLeastDigitOrSpecialChar = new RegExp("[\\d&#{}[()\\]\\-_~]");
  if (atLeastDigitOrSpecialChar.test(password)) currentProgress += 20;
  const notIncludeNames =
    !password.includes(firstname ?? "") && !password.includes(lastname ?? "");
  if (notIncludeNames) currentProgress += 20;
  const atLeast8Char = password.length >= 8;
  if (atLeast8Char) currentProgress += 20;
  return currentProgress;
}

export function encryptPassword(password: string) {
  const publicKey = process.env.REACT_APP_PUBLIC_KEY;
  if (!publicKey) return;
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  const encrypted = encrypt.encrypt(password);
  return encrypted ? encrypted : undefined;
}

function PasswordComponent({ form }: PasswordComponentProps) {
  const t = useIntlHelper();

  const passwordRules = useMemo(() => t("form.passwordRules").split("\n"), [t]);

  const progress = useMemo(() => {
    const { password, firstname, lastname } = form.values;
    return isAValidPassword(password, firstname, lastname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.password, form.values.firstname, form.values.lastname]);

  return (
    <>
      <Space h="md" />
      <PasswordInput
        label={t("form.password")}
        {...form.getInputProps("password")}
        required
      />
      <Space h="sm" />
      <Progress value={progress} />
      <Space h="sm" />
      <List>
        {passwordRules.map((rule) => (
          <List.Item key={rule}>{rule}</List.Item>
        ))}
      </List>
      <Space h="sm" />
      <PasswordInput
        label={t("form.confirmPassword")}
        {...form.getInputProps("confirmPassword")}
        required
      />
      <Space h="md" />
    </>
  );
}

export default PasswordComponent;
