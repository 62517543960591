import { showNotification, updateNotification } from "@mantine/notifications";

export function createLoading(id: string, message: string) {
  showNotification({
    message,
    color: "teal",
    loading: true,
    autoClose: false,
    id,
  });
}

export function updateIntoSuccess(id: string, message: string) {
  updateNotification({
    id,
    message,
    color: "green",
    autoClose: 2000,
  });
}

export function updateIntoError(
  id: string,
  error: any,
  t: (path: string) => string
) {
  let message = "";
  if (error.body && error.body.detail) {
    message = t(error.body.detail);
  } else if (error.message.detail) {
    message = t(error.message.detail);
  } else {
    message = t(error.message);
  }
  updateNotification({
    message,
    color: "red",
    id,
    autoClose: 5000,
  });
}
