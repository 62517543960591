/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PaymentMethodType {
    VIREMENT = 'virement',
    BANK_TRANSFER = 'bank_transfer',
    // CHEQUE = 'cheque',
}