/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { Container, Box } from "@mantine/core";
import { useNavigate, useLocation } from "react-router-dom";

import { OIDCComponent } from "@techlab/storybook";

import { observer } from "mobx-react";
import { authStore, useAuthStore } from "./AuthStore";
import { Interceptor } from "./Interceptor";

function callbackReceiveToken(userInfos: any) {
  console.log("receivetokencallback -> userInfos:");
  console.log(userInfos);
  authStore.setAccessToken(userInfos.access_token);
  authStore.setUserData(userInfos.user_data);
}

const Header = observer(
  ({ app }: { app: "backoffice" | "webapp" | "vmapp" }) => {
    const { authStore: authStoreHook } = useAuthStore();

    const devmode =
      process.env.REACT_APP_OIDC_DEVMODE === "true" ? true : false;
    const apphost: string = process.env.REACT_APP_OIDC_APPHOST
      ? process.env.REACT_APP_OIDC_APPHOST
      : "not_set";
    const clientid: string = process.env.REACT_APP_OIDC_CLIENTID
      ? process.env.REACT_APP_OIDC_CLIENTID
      : "not_set";
    const basic: string = process.env.REACT_APP_OIDC_BASIC
      ? process.env.REACT_APP_OIDC_BASIC
      : "not_set";
    const redirect: string = process.env.REACT_APP_OIDC_REDIRECT
      ? process.env.REACT_APP_OIDC_REDIRECT
      : "not_set";
    const gvtserver: string = process.env.REACT_APP_OIDC_AM_SERVER
      ? process.env.REACT_APP_OIDC_AM_SERVER
      : "not_set";
    const gvtdomain: string = process.env.REACT_APP_OIDC_AM_DOMAIN
      ? process.env.REACT_APP_OIDC_AM_DOMAIN
      : "not_set";

    const [displayName] = useState("-");
    const location = useLocation();
    const navigate = useNavigate();

    //const [allowedRoles, setAllowedRoles] = useState<string[]>([
    //  "APP_INT_PFID_BACKOFFICE",
    //]);

    useEffect(() => {
      console.log("Header.useLayoutEffect()");
      const pathState = new URLSearchParams(location.search).get("state");
      console.log(pathState);
      console.log(location.pathname, location.search);
      if (
        (app === "webapp" && location.pathname === "/profile") ||
        location.pathname === "/"
      ) {
        // If we find a state in the query, it is the destination route after the auth process
        if (pathState) {
          navigate(pathState);
          console.log(`Header.useLayoutEffect() navigate end -> ${pathState}`);
        }
      } else {
        console.log("Header.useLayoutEffect() end");
      }
    }, []);

    return (
      <Container
        sx={{
          height: 10,
          fontSize: 4,
          padding: 0,
        }}
      >
        <Box>
          <Interceptor />
          <OIDCComponent
            apphost={apphost}
            bearer={basic}
            clientid={clientid}
            devmode={devmode}
            devmode_data={{
              preferred_username: "gastecl",
              roles: ["APP_INT_PFID_BACKOFFICE", "APP_INT_PFID_VMAPP"],
              token: "dev_token_receive",
              user: "gastecl",
              email: "gastecl@pierre-fabre.com",
              given_name: "Loic",
              family_name: "GASTECEAU",
              account_id: "66850e9af87f3ba719d7126b",
              groups: [
                "APP_INT_PFID_ADMIN",
                "APP_INT_PFID_SUPPORT_PFFORMED_FR",
                "APP_INT_PFID_SUPPORT_PFFORMED_IT",
                "APP_INT_PFID_SUPPORT_PFFORMED_ES",
                "APP_INT_PFID_SUPPORT_PFFORMED",
                "APP_INT_PFID_SUPPORT_GOCELL",
                "APP_INT_PFID_SUPPORT_ORALCARE_FR",
                "APP_INT_PFID_SUPPORT",
              ],
            }}
            graviteedomaine={gvtdomain}
            graviteeserver={gvtserver}
            redirecturl={redirect}
            roles={
              app === "webapp"
                ? []
                : app === "backoffice"
                ? ["APP_INT_PFID_BACKOFFICE"]
                : ["APP_INT_PFID_VMAPP"]
            }
            userinfo
            window={window}
            customButton={() => {
              return <>&nbsp;&nbsp;{displayName}&nbsp;&nbsp;</>;
            }}
            receivetokencallback={callbackReceiveToken}
            notallowedcallback={() => {}}
            logoutcallback={() => {}}
            state={
              app === "vmapp"
                ? `${location.pathname}${encodeURIComponent(location.search)}`
                : `${location.pathname}${location.search}`
            }
          />
        </Box>
      </Container>
    );
  }
);

export default Header;
