/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */


export const SpecialityType = {
    "dentist": [
        "ORAL_AND_MAXILLOFACIAL_SURGERY",
        "ORTHODONTICS",
        "PAEDIATRICS",
        "STOMATOLOGY",
        "SURGEON",
        "OTHER",
    ],
    "doctor": [
        "NEUROLOGY",
        "CARDIOLOGY",
        "MIDWIFERY",
        "GENERAL_MEDICINE",
        "RHEUMATOLOGY",
        "GASTROENTEROLOGY",
        "ONCOLOGIST",
        "ENDOCRINOLOGY",
        "NEUROLOGY",
        "PAEDIATRICS",
        "GYNAECOLOGY",
        "ANGIOLOGY",
        "UROLOGY",
        "DERMATOLOGIST",
        "OTORHINOLARYNGOLOGY",
        "STOMATOLOGY",
        "INTERNAL_MEDICINE",
        "AESTHETIC_MEDICINE",
        "OTHER",
    ],
    "student": [
        "ORAL_AND_MAXILLOFACIAL_SURGERY",
        "ORTHODONTICS",
        "PAEDIATRICS",
        "STOMATOLOGY",
        "SURGEON",
        "NEPHROLOGY",
        "CARDIOLOGY",
        "MIDWIFERY",
        "GENERAL_MEDICINE",
        "RHEUMATOLOGY",
        "GASTROENTEROLOGY",
        "ONCOLOGY",
        "ENDOCRINOLOGY",
        "NEUROLOGY",
        "GYNAECOLOGY",
        "ANGIOLOGY",
        "UROLOGY",
        "DERMATOLOGY",
        "OTORHINOLARYNGOLOGY",
        "INTERNAL_MEDICINE",
        "AESTHETIC_MEDICINE",
        "OTHER",
    ],
    "medical_assistant": [
        "DENTIST",
        "OTHER",
    ],
    "dermatologist": [
        "DERMATOLOGIST",
    ],
    "hematologist": [
        "HEMATOLOGIST",
    ],
    "oncologist": [
        "ONCOLOGIST",
    ],
    "pharmacist": [
        "PHARMACIST",
    ],
    "nurse": [
        "NURSE",
    ],
    "biologist": [
        "BIOLOGIST",
    ],
    "technician": [
        "TECHNICIAN"
    ],
    "surgeon": [
        "PLASTIC_SURGEON"
    ],
    "other": [
        "OTHER"
    ],
    "allergologist": [
      "PLASTIC_SURGEON"
    ],
    "paediatrician": [
        "PAEDIATRICIAN"
    ]
}
