import { Loader, MantineProvider } from "@mantine/core";
import Page from "../components/Page/Page";
import { getTheme } from "../misc";
import { useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { OpenApi, useIntlHelper } from "@pfid/common";
import { showNotification } from "@mantine/notifications";

export default function Confirm() {
  const [searchParams] = useSearchParams();

  const params = useMemo(() => {
    return Object.fromEntries([...searchParams]);
  }, [searchParams]);

  const currentTheme = getTheme(params.theme || "default");
  const t = useIntlHelper();
  const [isAccountAlreadyCreated, setAlreadyCreated] = useState<boolean>(false);

  useEffect(() => {
    OpenApi.RequestsSendByHcpService.checkAccountActivationIntegrityHcpCheckAccountActivationIntegrityGet(
      window.location.href
    )
      .then((data) => {
        if (data.redirect_url) {
          setAlreadyCreated(data.account_already_activated);
          setTimeout(() => {
            window.location.replace(data.redirect_url);
          }, 3000);
        }
      })
      .catch((error) => {
        showNotification({
          message: error.body ? error.body.detail : error.message.detail,
          color: "red",
        });
      });
  }, []);

  return (
    <MantineProvider theme={currentTheme.object}>
      <Page>
        {isAccountAlreadyCreated ? (
          <div>
            <p>{t("confirm.redirect")}</p>
            <Loader color="blue" />
          </div>
        ) : (
          <div>
            <p>{t("confirm.waiting")}</p>
            <Loader color="blue" />
          </div>
        )}
      </Page>
    </MantineProvider>
  );
}
