import { MantineThemeOverride } from "@mantine/core";

const defaultTheme: MantineThemeOverride = {
  colorScheme: "light",
  colors: {
    primary: [
      "#EBF0F9",
      "#C7D4EF",
      "#A3B9E5",
      "#7F9DDB",
      "#5C82D1",
      "#3866C7",
      "#2D529F",
      "#213D78",
      "#162950",
      "#0B1428",
    ],
    secondary: [
      "#E7F7FD",
      "#BDE9FA",
      "#92DBF6",
      "#68CDF3",
      "#3DBFF0",
      "#13B1EC",
      "#0F8DBD",
      "#0B6A8E",
      "#07475F",
      "#04232F",
    ],
    warning: [
      "#FCF7E9",
      "#F6E9C1",
      "#F0DB99",
      "#EACC71",
      "#E4BE49",
      "#DEB021",
      "#B28D1A",
      "#856914",
      "#59460D",
      "#2C2307",
    ],
    error: [
      "#FCE8E8",
      "#F7BFBF",
      "#F39696",
      "#EE6D6D",
      "#E94444",
      "#E41B1B",
      "#B61616",
      "#891010",
      "#5B0B0B",
      "#2E0505",
    ],
    success: [
      "#EAFAF1",
      "#C5F2D8",
      "#9FEABF",
      "#7AE1A5",
      "#54D98C",
      "#2FD073",
      "#25A75C",
      "#1C7D45",
      "#13532E",
      "#092A17",
    ],
    info: [
      "#ECF5F8",
      "#CBE2EC",
      "#AAD0DF",
      "#88BED2",
      "#67ABC6",
      "#4699B9",
      "#387A94",
      "#2A5C6F",
      "#1C3D4A",
      "#0E1F25",
    ],
  },
  headings: {
    sizes: {
      h1: { fontSize: 26, lineHeight: "61.6px", fontWeight: 700 },
      h2: { fontSize: 24, lineHeight: "52.8px", fontWeight: 600 },
      h3: { fontSize: 16, lineHeight: "44px", fontWeight: 600 },
    },
  },
  fontFamily: "Open Sans",
  fontSizes: {
    md: 16,
    xs: 12,
  },
  primaryColor: "primary",
  components: {
    Title: {
      styles: {
        root: {
          "&:is(h1)": { color: "primary" },
          "&:is(h2)": { color: "#333333" },
          "&:is(h3)": { color: "#333333" },
        },
      },
    },
    TypographyStylesProvider: {
      styles: {
        root: {
          "&:is(h1)": { color: "primary" },
          "&:is(h2)": { color: "#333333" },
          "&:is(h3)": { color: "#333333" },
        },
      },
    },
    Text: {
      styles: {
        root: {
          color: "#333333",
        },
      },
    },
  },
};

export default defaultTheme;
