import { Group, Space, Text, Tooltip } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import { Link, Trash } from "tabler-icons-react";
import { useIntlHelper } from "../../utils/misc";

type FilesListProps = {
  inputKey: string;
  value?: { file_name: string; file_id: string }[];
  onRemove?: (id: string) => void;
};

const FilesList = ({ value, inputKey, onRemove }: FilesListProps) => {
  const t = useIntlHelper();
  if (!value) return null;

  return (
    <>
      <Space h="md" />
      <Text sx={{ marginBottom: 4, fontSize: 14, fontWeight: 500 }}>
        {t(`form.${inputKey}`)}
      </Text>

      {value.map(({ file_name, file_id }) => (
        <Group key={file_id}>
          <li>{file_name}</li>
          <Link
            style={{ cursor: "pointer" }}
            onClick={() => {
              // @ts-expect-error window not null
              window
                .open(
                  `${process.env.REACT_APP_IMG_URL}/support/file/${file_id}`,
                  "_blank"
                )
                .focus();
            }}
          />
          {onRemove && <Trash onClick={() => onRemove(file_id)} />}
        </Group>
      ))}
    </>
  );
};

export default FilesList;
