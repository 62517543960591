import { SourceSiteType } from "../../client";
import { ProfessionType } from "../../client";

const professionTabcell = {
  HEMATOLOGIST: ProfessionType.HEMATOLOGIST,
  ONCOLOGIST: ProfessionType.ONCOLOGIST,
  PEDIATRICIAN: ProfessionType.PEDIATRICIAN,
  PHARMACIST: ProfessionType.PHARMACIST,
  BIOLOGIST: ProfessionType.BIOLOGIST,
  TECHNICIAN: ProfessionType.TECHNICIAN,
  NURSE: ProfessionType.NURSE,
  MEDICAL_ASSISTANT: ProfessionType.MEDICAL_ASSISTANT,
  OTHER: ProfessionType.OTHER,
};

export function getProfessions(sourceSite: string) {
  switch (sourceSite) {
    case SourceSiteType.TABCELL:
      return professionTabcell;
    default:
      return ProfessionType;
  }
}
