import { PropsWithChildren } from "react";
import { AppShell, Container } from "@mantine/core";

import Footer from "../Footer/Footer";
import Header from "../Header/Header";

type PageProps = PropsWithChildren<{
  footer?: JSX.Element;
  header?: JSX.Element;
}>;

const Page = ({ children, footer, header }: PageProps) => (
  <AppShell header={header ?? <Header />} footer={footer ?? <Footer />}>
    <Container>{children}</Container>
  </AppShell>
);

export default Page;
