/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_modify_account_hcp_account__account_id__put } from '../models/Body_modify_account_hcp_account__account_id__put';
import type { Body_update_draft_by_account_draft_id_hcp_account__account_id__draft__draft_id__put } from '../models/Body_update_draft_by_account_draft_id_hcp_account__account_id__draft__draft_id__put';
import type { HcpAccountDraftResponse } from '../models/HcpAccountDraftResponse';
import type { HcpAccountResponse } from '../models/HcpAccountResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HcpAccountsService {

    /**
     * Retrieve account/profile by id
     * @param accountId
     * @returns HcpAccountResponse Successful Response
     * @throws ApiError
     */
    public static findAccountByIdHcpAccountAccountIdGet(
        accountId: string,
    ): CancelablePromise<HcpAccountResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/hcp/account/{account_id}',
            path: {
                'account_id': accountId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update profile/account by Hcp
     * send ONLY the data that you want to update, leaving the rest intact
     * @param accountId
     * @param formData
     * @returns HcpAccountResponse Successful Response
     * @throws ApiError
     */
    public static modifyAccountHcpAccountAccountIdPut(
        accountId: string,
        formData?: Body_modify_account_hcp_account__account_id__put,
    ): CancelablePromise<HcpAccountResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/hcp/account/{account_id}',
            path: {
                'account_id': accountId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Retrieve account/profile's draft by id
     * @param accountId
     * @returns HcpAccountDraftResponse Successful Response
     * @throws ApiError
     */
    public static findDraftByAccountIdHcpAccountAccountIdDraftGet(
        accountId: string,
    ): CancelablePromise<HcpAccountDraftResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/hcp/account/{account_id}/draft',
            path: {
                'account_id': accountId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update draft
     * @param accountId
     * @param draftId
     * @param formData
     * @returns HcpAccountDraftResponse Successful Response
     * @throws ApiError
     */
    public static updateDraftByAccountDraftIdHcpAccountAccountIdDraftDraftIdPut(
        accountId: string,
        draftId: string,
        formData?: Body_update_draft_by_account_draft_id_hcp_account__account_id__draft__draft_id__put,
    ): CancelablePromise<HcpAccountDraftResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/hcp/account/{account_id}/draft/{draft_id}',
            path: {
                'account_id': accountId,
                'draft_id': draftId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}