import { MantineProvider } from "@mantine/core";
import { useAuthStore, HeaderApp, Page } from "@pfid/common";
import { observer } from "mobx-react";
import { ReactElement, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import { defaultTheme } from "../themes";

const ProfileApp = observer(() => {
  const { authStore } = useAuthStore();
  const [content, setContent] = useState<ReactElement>(<></>);
  useEffect(() => {
    console.log("ProfileApp.useEffect()");
    if (
      authStore.userData === undefined ||
      authStore.userData.account_id === undefined ||
      authStore.userData.account_id === ""
    ) {
      console.log("ProfileApp.useEffect() -> forbiden");
      setContent(<></>);
    } else {
      console.log("ProfileApp.useEffect() -> allowed");
      setContent(<Outlet />);
    }
  }, [authStore.userData]);

  return (
    <MantineProvider theme={defaultTheme}>
      <Page footer={<></>} header={<></>}>
        <HeaderApp app="webapp" />
        {content}
      </Page>
    </MantineProvider>
  );
});

export default ProfileApp;
