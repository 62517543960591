import { MantineThemeOverride } from "@mantine/core";
import { defaultTheme } from "./index";

const oralCareTheme: MantineThemeOverride = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    primary: [
      "#FFE9E5",
      "#FFC1B8",
      "#FF998A",
      "#FF715C",
      "#FF492E",
      "#FF2100",
      "#CC1A00",
      "#991400",
      "#660D00",
      "#330700",
    ],
  },
};

export default oralCareTheme;
