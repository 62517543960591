/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DenodoHcpInfoResponse } from '../models/DenodoHcpInfoResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DenodoService {

    /**
     * Retrieve HCP informations from its registration number
     * @param registrationNumber
     * @returns DenodoHcpInfoResponse Successful Response
     * @throws ApiError
     */
    public static findFrHcpByRegistrationNumberAndCountryRefHcpFrRegistrationNumberGet(
        registrationNumber: any,
    ): CancelablePromise<DenodoHcpInfoResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ref/hcp/FR/{registration_number}',
            path: {
                'registration_number': registrationNumber,
            },
            errors: {
                422: `Validation Error`,
            },
            headers: {
                'X-Gravitee-Api-Key': process.env.REACT_APP_CORE_NEW_REQUEST_APIKEY,
            }
        });
    }

    /**
     * Retrieve HCP informations from its registration number and country
     * @param registrationNumber
     * @param countryCode
     * @returns DenodoHcpInfoResponse Successful Response
     * @throws ApiError
     */
    public static findHcpByRegistrationNumberAndCountryRefHcpCountryRegistrationNumberGet(
        registrationNumber: any,
        countryCode: any,
    ): CancelablePromise<DenodoHcpInfoResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ref/hcp/{country_code}/{registration_number}',
            path: {
              'registration_number': registrationNumber,
              'country_code': countryCode
            },
            errors: {
                422: `Validation Error`,
            },
            headers: {
                'X-Gravitee-Api-Key': process.env.REACT_APP_CORE_NEW_REQUEST_APIKEY,
            }
        });
    }

}