/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ProfessionType {
    DENTIST = 'dentist',
    DERMATOLOGIST = 'dermatologist',
    DOCTOR = 'doctor',
    MEDICAL_ASSISTANT = 'medical_assistant',
    STUDENT = 'student',
    HEMATOLOGIST = 'hematologist',
    ONCOLOGIST = 'oncologist',
    PHARMACIST = 'pharmacist',
    NURSE = 'nurse',
    BIOLOGIST = 'biologist',
    TECHNICIAN = 'technician',
    SURGEON = 'surgeon',
    PEDIATRICIAN = 'pediatrician',
    ALLERGOLOGIST = 'allergologist',
    OTHER = 'other',
}