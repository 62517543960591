import { useState, useEffect } from "react";

import { Group, Space, Image, Box } from "@mantine/core";
import axios from "axios";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";

import { useAuthStore } from "../Header/AuthStore";
import occitanieCircle from "../../assets/occitanie_circle.png";

interface VersionData {
  version: string;
}

const Footer = observer(() => {
  const versionFull: string = process.env.REACT_APP_VERSION_FULL
    ? process.env.REACT_APP_VERSION_FULL
    : "0.0.0.xxxx";
  const apiHostUrl: string | undefined = process.env.REACT_APP_CORE_URL;

  const [serverVersion, setServerVersion] = useState<string>("🔹");
  const { authStore } = useAuthStore();

  useEffect(() => {
    if (authStore.accessToken) {
      axios
        .get<VersionData>(`${apiHostUrl}/version`)
        .then((res) => {
          setServerVersion(res.data.version);
        })
        .catch((error) => {
          setServerVersion("💣");
          console.error(error);
        });
    } else {
      setServerVersion("🔸");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore.accessToken]);

  return (
    <Box
      sx={{
        fontSize: "12px",
        width: "99%",
        position: "fixed",
        bottom: 0,
      }}
    >
      <Group position="center" spacing={6} sx={{ margin: "auto" }}>
        <Link to="/">{`PFID 💻${versionFull} ⚙️${serverVersion}`}</Link>
        <>{`©️Pierre Fabre SA 2022 - Made with ❤️by`}</>
        <a href="mailto:techlab@pierre-fabre.com">
          <span className="tech">Tech</span>
          <span className="lab">Lab</span>
        </a>
        in Tarn
        <Image width={14} height={14} src={occitanieCircle} alt="Occitanie logo" />
        🇫🇷
      </Group>
      <Space h="md" />
    </Box>
  );
});

export default Footer;
