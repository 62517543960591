import { useIntl } from "react-intl";

export function useIntlHelper(): (id: string) => string {
  const intl = useIntl();
  return (id) => intl.formatMessage({ id });
}

export function getNavigatorLanguage() {
  const userLanguage = navigator.language;
  if (userLanguage.includes("fr")) {
    return "FR";
  }
  if (userLanguage.includes("es")) {
    return "ES";
  }
  if (userLanguage.includes("it")) {
    return "IT";
  }
  return "EN";
}
