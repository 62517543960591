import {
  Container,
  Box,
  Image,
  MantineProvider,
  Space,
  Title,
  Text,
} from "@mantine/core";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useIntlHelper,
  UserProfileForm,
  SourceSiteType,
  getEnumKeyByEnumValue,
} from "@pfid/common";
import { useIntl } from "react-intl";

import Page from "../components/Page/Page";
import { getTheme } from "../misc";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export default function CreationCompte() {
  const [searchParams] = useSearchParams();

  const params = useMemo(() => {
    return Object.fromEntries([...searchParams]);
  }, [searchParams]);

  const currentTheme = getTheme(params.theme || "default");
  const t = useIntlHelper();
  const intl = useIntl();

  return (
    <MantineProvider theme={currentTheme.object}>
      <Page>
        <Space h="md" />
        <Container size="xs" sx={{ maxWidth: 365 }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Image
              width={192}
              height={192}
              fit="contain"
              src={
                process.env.PUBLIC_URL +
                "/themes/" +
                currentTheme.name +
                "/logo.png"
              }
              alt="logo"
            />
          </Box>

          <Title order={2}>{t("form.request.title")}</Title>
          <Text>{t("form.request.subTitle")}</Text>
          <Space h="lg" />
          <GoogleReCaptchaProvider reCaptchaKey="6LeNUgAiAAAAADhonC-O3yMPisqOCIJGqsFEzmqC">
            <UserProfileForm
              register
              redirect={params.redirect}
              simple={!!params.simple}
              // @ts-expect-error country is a CountryType
              country={params?.country ?? ""}
              // @ts-expect-error country is a ProfessionType
              profession={params?.profession ?? ""}
              sourceTheme={currentTheme.name}
              sourceSite={
                SourceSiteType[
                  getEnumKeyByEnumValue(
                    SourceSiteType,
                    currentTheme.name.toUpperCase(),
                    SourceSiteType.PORTAL
                  )
                ]
              }
            />
          </GoogleReCaptchaProvider>
        </Container>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <a
            href={`${
              process.env.PUBLIC_URL
            }/Politique_confidentialité_PFID_${intl.locale.toUpperCase()}.pdf`}
            target="_blank"
            style={{ color: "inherit", textDecoration: "inherit" }}
            download
          >
            {t("form.confid_policy")}
          </a>
        </Box>
      </Page>
    </MantineProvider>
  );
}
