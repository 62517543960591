/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SourceSiteType {
    PORTAL = 'PORTAL',
    PFOC = 'PFOC',
    TABCELL = 'TABCELL',
    PF4MED = 'PF4MED',
    SOS = 'SOS',
    ECZEMACAREPLUS = 'ECZEMACAREPLUS'
}