/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_create_hcp_account_by_hcp_hcp_request_post } from '../models/Body_create_hcp_account_by_hcp_hcp_request_post';
import type { CheckActivationLinkIntegrityResponse } from '../models/CheckActivationLinkIntegrityResponse';
import type { ResponseRequestId } from '../models/ResponseRequestId';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RequestsSendByHcpService {

    /**
     * Add a new hcp account request by hcp
     * @param formData
     * @returns ResponseRequestId Successful Response
     * @throws ApiError
     */
    public static createHcpAccountByHcpHcpRequestPost(
        formData: Body_create_hcp_account_by_hcp_hcp_request_post,
    ): CancelablePromise<ResponseRequestId> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/hcp/request',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
            headers: {
                'X-Gravitee-Api-Key': process.env.REACT_APP_CORE_NEW_REQUEST_APIKEY,
            }
        });
    }

    /**
     * Check account activation integrity with unique activation_key & b64 encoded email
     * @param activationLink
     * @returns CheckActivationLinkIntegrityResponse Integrity is correct
     * @throws ApiError
     */
    public static checkAccountActivationIntegrityHcpCheckAccountActivationIntegrityGet(
        activationLink: string,
    ): CancelablePromise<CheckActivationLinkIntegrityResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/hcp/check_account_activation_integrity',
            query: {
                'activation_link': activationLink,
            },
            errors: {
                400: `Fonctionnal error`,
                422: `Validation Error`,
                503: `Technical error`,
            },
            headers: {
              'X-Gravitee-Api-Key': process.env.REACT_APP_CORE_NEW_REQUEST_APIKEY,
            },
        });
    }

}